import React from 'react'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { DomandeIllustration } from '@components/atoms/Icons'
import { WContainer } from 'components/atoms'
import { Page, StrumentiLinkUtili } from 'components/organisms'
import styles from './Custom404Template.module.sass'

const Custom404Template = () => {
  const t = useTrans()
  const { menu } = useSelector((state) => state.menu)
  const navMenu = menu?.find((item) => item.key === 'main')
  const hpmenu = menu?.find((item) => item.key === 'hp')
  const strumentilinkUtili = hpmenu?.children.find((item) => item.key === 'strumenti_link_utili')

  return (
    <Page pagina={{}} item={{}}>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <DomandeIllustration />
          </div>
          <h1 className={styles.title}>{t('Ops, la pagina che stai cercando non esiste')}</h1>
        </div>
        {navMenu?.children?.filter((x) => !!x.url)?.length > 0 && (
          <WContainer variant="sm">
            <div className={styles.argomenti}>
              <p className={styles.argomentiTitle}>{t('Prova con uno di questi argomenti')}</p>
              <div className={styles.argomentiList}>
                {navMenu.children
                  .filter((x) => !!x.url)
                  .map((x) => (
                    <Link legacyBehavior href={x.url}>
                      <a className={styles.argomentiItem}>{x.name}</a>
                    </Link>
                  ))}
              </div>
            </div>
          </WContainer>
        )}
      </div>
      {strumentilinkUtili?.children?.length > 0 && (
        <div className={styles.bottom}>
          <WContainer variant="sm">
            <StrumentiLinkUtili item={strumentilinkUtili} />
          </WContainer>
        </div>
      )}
    </Page>
  )
}

export default Custom404Template
